const optimizePageName = (path, search) => {
    if (path === "/" && !search) {
      return "Home";
    }
  
    switch (path) {
      case "/buy-gift-cards":
        return "Buy Page";
      case "/deals":
        return "Deals Page";
      case "/sell-gift-cards":
      case "/login": // Considered as the same page
        return "Sell Page";
      case "/cards":
        return "Wallet";
      default:
        return "";
    }
  };
  
  export default optimizePageName;
  