import React from 'react'

export function Card({ className, ...props }) {
    return (
        <div
            className={`rounded-lg border border-gray-200 bg-white text-gray-950 shadow-sm ${className}`}
            {...props}
        />
    )
}
