import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import Buy, { loader as buyLoader } from "./pages/Buy";
import Sell from "./pages/Sell";
import SellCard, { loader as sellCardLoader } from "./pages/SellCard";
import YourCards from "./pages/YourCards";
import YourBalance from "./pages/YourBalance";
import AddCard from "./pages/AddCard";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Deals from "./pages/Deals";
import ActivatePage from "./components/ActivatePage";
import "./assets/styles/app.css";
import { NotificationProvider } from "./components/Notification";
import SetCookies from "./components/SetCookies";
import reportWebVitals from "./reportWebVitals";
import "./globalStyles.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/buy-gift-cards",
    element: <Buy />,
    loader: buyLoader,
  },
  {
    path: "/buy-gift-cards/:storeId",
    element: <Buy />,
    loader: buyLoader,
  },
  {
    path: "/sell-gift-cards",
    element: <Sell />,
  },
  {
    path: "/sell-gift-cards/:storeId",
    element: <SellCard />,
    loader: sellCardLoader,
  },
  {
    path: "/sell-gift-cards/:storeId/:codeId",
    element: <SellCard />,
    loader: sellCardLoader,
  },
  {
    path: "/cards",
    element: <YourCards />,
  },
  {
    path: "/balance",
    element: <YourBalance />,
  },
  {
    path: "/cards/new",
    element: <AddCard />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/deals",
    element: <Deals />,
  },

  {
    path: "/activating-cashback/:merchantId?/:deviceId?/:storeUrl?",
    element: <ActivatePage />,
  },

  {
    path: "/invited_by_link",
    element: <SetCookies />,
  },
  
]);

const App = () => {
  return (
    <div className="bg-white">
      <NotificationProvider>
        <RouterProvider router={router}></RouterProvider>
      </NotificationProvider>
    </div>
  );
};

function sendMessageToExtension(payload) {
  try {
    console.log("sdfsdfsd:sendMessageToExtension", {
      type: "logging",
      text: "Logged In",
    });
    window.postMessage(
      {
        type: "FROM_CLEO_CARD_CALLBACK",
        payload,
      },
      "*"
    );
  } catch (err) {
    console.log("err:sendMessageToExtension", err);
  }
}

const handleAuth0Redirect = (appState) => {
  console.log("appState?.returnTo", appState?.returnTo);
  sendMessageToExtension({ type: "logging", text: "Logged In" });
  if (appState?.returnTo) {
    window.location.replace(appState.returnTo);
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      // connection: "email",
      scope: "openid profile email offline_access",
    }}
    onRedirectCallback={handleAuth0Redirect}
    cacheLocation="localstorage"
    useRefreshTokens={true}
  >
    <App />
    {/* <div>
      <button
        onClick={() =>
          sendMessageToExtension({ type: "logging", text: "Logged In" })
        }
      >
        Send Message to Extension
      </button>
    </div> */}
  </Auth0Provider>
);

reportWebVitals();
