import { Fragment, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Card } from "./ModalCard"
import { classNames } from "../../utils/dom"
import Paypal from '../../assets/images/Paypal.png'
import stripe from "../../assets/images/stripe.png"
import Feature from "../../assets/images/Featured icon.png"

const PayoutSelectionModal = ({
  title = "Pay Out Funds",
  amount,
  open,
  onOpen,
  processing,
  onConfirm,
  defaultPayout = "paypal",

}) => {
  const cancelButtonRef = useRef(null)
  const [selectedPayout, setSelectedPayout] = useState(defaultPayout);


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel>
                <Card className="w-[400px] p-6">
                  <div className="flex items-start gap-4">
                    <img src={Feature} alt="Stripe" className="w-10 h-10" />
                    <div>
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {title}
                      </Dialog.Title>
                      <p className="text-sm text-gray-500">
                        Select how you'd like to receive your {amount} from Cleo Cards
                      </p>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-between gap-3">
                    <button
                      onClick={() => setSelectedPayout("paypal")}
                      className={classNames(
                        "flex w-full items-center gap-3 rounded-lg border p-4 text-left transition-colors",
                        selectedPayout === "paypal"
                          ? "border-[#4840BD] bg-violet-50 "
                          : "border-gray-200 hover:bg-gray-50"
                      )}
                    >
                      <img src={Paypal} alt="PayPal" className="h-6" />
                      <div className="h-4 w-4 ml-auto rounded-full border border-violet-600 flex-shrink-0 p-[3px]">
                        {selectedPayout === "paypal" && (
                          <div className="h-full w-full rounded-full bg-violet-600" />
                        )}
                      </div>
                    </button>
                    <button
                      onClick={() => setSelectedPayout("stripe")}
                      className={classNames(
                        "flex w-full items-center gap-3 rounded-lg border p-4 text-left transition-colors",
                        selectedPayout === "stripe"
                          ? "border-[#4840BD] bg-violet-50"
                          : "border-gray-200 hover:bg-gray-50"
                      )}
                    >
                      <img src={stripe} alt="Stripe" className="h-6" />
                      <div className="h-4 w-4 ml-auto rounded-full border border-violet-600 flex-shrink-0 p-[3px] ">
                        {selectedPayout === "stripe" && (
                          <div className="h-full w-full rounded-full bg-violet-600" />
                        )}
                      </div>
                    </button>
                  </div>

                  <button
                    onClick={() => selectedPayout && onConfirm(selectedPayout)}
                    disabled={!selectedPayout || processing}
                    className={classNames(
                      "mt-6 w-full rounded-lg bg-[#4840BD] px-4 py-3 text-sm font-medium text-white transition-colors hover:bg-[#6366F1]",
                      (!selectedPayout || processing) && "opacity-50 cursor-not-allowed"
                    )}
                  >
                    Cash Out Now
                  </button>
                </Card>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default PayoutSelectionModal
