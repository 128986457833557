import React from "react";

//Paid  Pending Confirmed  Ready
// StatusPill.js
const StatusPill = ({ status }) => {
  const getStatusProps = (status) => {
    switch (status) {
      case "Paid":
        return { color: "bg-green-500", text: "Paid" };
      case "PENDING":
        return { color: "bg-yellow-500", text: "Pending Confirmation" };
      case "Confirmed":
        return { color: "bg-green-500", text: "Confirmed" };

      default:
        return { color: "bg-gray-400", text: "Unknown Status" };
    }
  };

  const { color, text } = getStatusProps(status);

  return (
    <div className="flex items-center gap-1">
      <div className={`${color} rounded-full h-2.5 w-2.5`}></div>
      <div>
        <span className="text-xs font-medium">{text}</span>
      </div>
    </div>
  );
};

export default StatusPill;
