// components/DealsComponent.js
"use client";
import React, { useEffect, useState } from "react";
import { Drawer, Card } from "../../ui";
import Navbar from "../../components/Navbar";
import Content from "../../components/Content";
import Footer from "../../components/Footer";
import DealCard from "./components/DealCard";
import { getAllDeals, sendMixPanelEvent } from "../../api";
import mixpanelEventDataWrapped from "../../utils/mixPanelWrapped";
import {
  mixpanelEvent,
  mixpanelProperties,
} from "../../constants/mixPanelConstant";
import DealDetails from "./components/DealDetails";
import SkeletonLoader from "../../components/SkeletonLoader";
import CustomScrollbar from "../../components/Scrollbar";
import { useAuth0 } from "@auth0/auth0-react";
import { generateUUID, getCookie, setCookie } from "../../utils/dom";

export default function DealsComponent() {
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [deals, setDeals] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { getAccessTokenSilently } = useAuth0();
  const fetchDeal = async () => {
    try {
      setIsLoading(true);
      const response = await getAllDeals();
      if (response.length > 0) {
        setSelectedDeal(response[0]);
      }
      setDeals(response);
      setIsLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    fetchDeal();
  }, []);
  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Navbar />
      <Content>
        <div className="w-full max-w-7xl mx-auto space-y-3">
          <div>
            <h1 className="text-3xl font-semibold">Weekly Deal Roundup</h1>
            <p className="text-[#101828] text-sm">
              Deals around internet this week!
            </p>
          </div>
          <div className="md:flex md:space-x-3">
            <CustomScrollbar
              maxHeight={700}
              width="w-full md:w-1/3" // Full width on small screens, 33% on medium and above
            >
              <div className="w-full p-0">
                {isLoading ? (
                  <SkeletonLoader
                    height={100}
                    length={4}
                    skeletonGap={15}
                    width="100%"
                  />
                ) : deals.length === 0 ? (
                  <div className="text-center bg-white rounded-md h-full flex justify-center items-center text-base font-semibold p-4">
                    No cards available
                  </div>
                ) : (
                  deals.map((deal) => (
                    <React.Fragment key={deal.id}>
                      <DealCard
                        deal={deal}
                        onClick={async () => {
                          setSelectedDeal(deal);
                          if (isMobile) setIsDrawerOpen(deal.id);

                          let token = null;
                          const mixPanelObject = {
                            [mixpanelProperties.STORE_NAME]: deal.storeId,
                          };
                          try {
                            token = await getAccessTokenSilently();
                          } catch (err) {}

                          if (token) {
                            Object.assign(mixPanelObject, {
                              [mixpanelProperties.USER_LOGGED_In]: "true",
                            });
                          } else {
                            let UniqueID = null;
                            const loggedOutUserId = getCookie("userId");
                            if (loggedOutUserId) {
                              UniqueID = loggedOutUserId;
                            } else {
                              UniqueID = generateUUID();
                              setCookie("userId", UniqueID, 7); // Cookie expires in 7 days
                            }

                            Object.assign(mixPanelObject, {
                              [mixpanelProperties.USER_LOGGED_In]: "false",
                              [mixpanelProperties.UNIQUE_ID]: UniqueID,
                            });
                          }

                          try {
                            const mixpanelEventData = mixpanelEventDataWrapped(
                              mixpanelEvent.DEAL_CLICKED,
                              mixPanelObject
                            );

                            await sendMixPanelEvent(token, mixpanelEventData);
                          } catch (err) {
                            console.log("erere", err);
                          }
                        }}
                        isSelected={
                          deal.id === (selectedDeal && selectedDeal.id)
                        }
                      />

                      {isMobile && (
                        <Drawer
                          hideCloseBtn={true}
                          placement={"bottom"}
                          isOpen={isDrawerOpen === deal.id}
                          onClose={() => setIsDrawerOpen(null)}
                        >
                          <DealDetails
                            onClose={() => setIsDrawerOpen(null)}
                            deal={deal}
                            isMobile={true}
                          />
                        </Drawer>
                      )}
                    </React.Fragment>
                  ))
                )}
              </div>
            </CustomScrollbar>

            {isLoading && (
              <div className="hidden md:block md:w-4/6">
                <Card className="bg-white border shadow-none">
                  <div className="p-6">
                    <>
                      <SkeletonLoader
                        height={30}
                        length={3}
                        skeletonGap={15}
                        width="50%"
                      />
                      <SkeletonLoader
                        height={30}
                        length={4}
                        skeletonGap={15}
                        width="20%"
                        showTwoInLine={true}
                      />
                      <SkeletonLoader
                        height={100}
                        length={3}
                        skeletonGap={15}
                        width="50%"
                      />
                    </>
                  </div>
                </Card>
              </div>
            )}

            {!isLoading && selectedDeal && (
              <div className="hidden md:block pt-1 md:w-4/6">
                <Card className="bg-white border shadow-none">
                  <div className="p-6">
                    {selectedDeal && (
                      <DealDetails isLoading={isLoading} deal={selectedDeal} />
                    )}
                  </div>
                </Card>
              </div>
            )}
          </div>
        </div>
      </Content>
      <Footer />
    </>
  );
}
