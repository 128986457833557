import React, { useState, useEffect } from "react";
import Loader from "../assets/images/loader.svg";
import ActivateImg from "../assets/images/activate.svg";
import { useLocation } from "react-router-dom";

const SetCookies = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");
  const userId = queryParams.get("userId");

  useEffect(() => {
    // Function to set a cookie
    const setCookie = (name, value, days) => {
      const expires = new Date();
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000); // Set expiration time
      document.cookie = `${name}=${encodeURIComponent(
        value
      )};expires=${expires.toUTCString()};path=/`;
    };

    // Set cookies for source and userId if they exist
    if (source) {
      setCookie("source", source, 7); // Cookie expires in 7 days
    }
    if (userId) {
      setCookie("userId", userId, 7); // Cookie expires in 7 days
    }
    window.location.href = process.env.REACT_APP_EXTENSION_URL;
  }, [source, userId]); // Run useEffect when source or userId changes
};

export default SetCookies;
