import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import mixpanelEventDataWrapped from "../../utils/mixPanelWrapped";
import {
  mixpanelEvent,
  mixpanelProperties,
} from "../../constants/mixPanelConstant";
import Profile from "./Profile";
import { sendMixPanelEvent } from "../../api";
import LogoIcon from "../../assets/icons/logo.png";
import optimizePageName from "../../utils/optimizePageName";
import { generateUUID, setCookie } from "../../utils/dom";
const menuItems = [
  { to: "/buy-gift-cards", label: "Buy" },
  { to: "/sell-gift-cards", label: "Sell" },
  { to: "/cards", label: "Your Cards" },
  { to: "/balance", label: "Your Balance" },
  { to: "/deals", label: "Deals" },

  // { to: '/how-it-works', label: 'How it Works' },
];

const Navbar = () => {
  const location = useLocation();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [routeLoaded, setRouteLoaded] = React.useState(false);

  // whenever route changes, scroll to the top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (routeLoaded === false) {
      checkExtensionInstalled();
      setRouteLoaded(true);
    }
  }, [location]);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) return parts.pop().split(";").shift();
    return null; // Return null if the cookie is not found
  };

  const checkExtensionInstalled = async () => {
    try {
      let uuid = getCookie("userId");
      let source = getCookie("source");
      if (uuid === null || uuid === undefined) {
        uuid = generateUUID();
        setCookie("userId", uuid, 7); // Cookie expires in 7 days
      }
      const extensionId = "loplcclfgoogdeijmfblhhkfpfkdlmhk"; // Replace with your actual extension ID
      const extensionURL = `chrome-extension://${extensionId}/close.png`; // Change to a resource in your extension
      const { pathname, search } = location;

      const pageName = optimizePageName(pathname, search);
      let repsonseData = null;
      try {
        repsonseData = await fetch(extensionURL);
      } catch (err) {
        repsonseData = { ok: false };
      }
      // Only proceed if the extension is installed
      if (pageName != "") {
        const mixPanelEventObj = {
          [mixpanelProperties.PAGE]: pageName,
          [mixpanelProperties.SOURCE]: source
            ? source // If source exists, use it
            : "Direct",
        };
        try {
          let token = null;
          try {
            token = await getAccessTokenSilently();
            Object.assign(mixPanelEventObj, {
              [mixpanelProperties.UNIQUE_ID]: uuid,
              [mixpanelProperties.USER_LOGGED_In]: "true",
            });
          } catch (err) {
            Object.assign(mixPanelEventObj, {
              [mixpanelProperties.UNIQUE_ID]: uuid,
              [mixpanelProperties.USER_LOGGED_In]: "false",
            });
          }

          const mixpanelEventData = mixpanelEventDataWrapped(
            mixpanelEvent.VISITED_PAGE,
            mixPanelEventObj
          );
          await sendMixPanelEvent(token, mixpanelEventData);
        } catch (tokenError) {
          console.error("Error fetching token:", tokenError);
          // Still assume not logged in if token fetching fails
          try {
            const mixpanelEventData = mixpanelEventDataWrapped(
              mixpanelEvent.VISITED_PAGE,
              {
                [mixpanelProperties.USER_LOGGED_In]: "false",
                [mixpanelProperties.PAGE]: pageName,
                [mixpanelProperties.UNIQUE_ID]: uuid,
              }
            );
            await sendMixPanelEvent(null, mixpanelEventData);
          } catch (err) {
            console.log("err", err);
          }
        }
      } else {
        console.error(
          "Extension not installed or response not OK:",
          repsonseData
        );
      }
    } catch (fetchError) {
      console.error("Error fetching extension resource:", fetchError);
      // Do not call sendMixPanelEvent if extension is not installed
    } finally {
      setRouteLoaded(true);
    }
  };

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-0">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src={LogoIcon}
                      alt="Cleo Cards"
                      width={505}
                      height={192}
                    />
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src={LogoIcon}
                      alt="Cleo Cards"
                      width={505}
                      height={192}
                    />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {menuItems.map(({ to, label, isAuth }) => {
                    if (!isAuthenticated && isAuth) return null;

                    const isCurrent =
                      location.pathname === to ||
                      location.pathname.startsWith(`${to}/`);
                    const className = isCurrent
                      ? "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                      : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700";
                    return (
                      <Link key={label} to={to} className={className}>
                        {label}
                      </Link>
                    );
                  })}
                </div>
              </div>

              {/* Profile dropdown */}
              <Profile />
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pt-2 pb-4">
              {menuItems.map(({ to, label, isAuth }) => {
                if (!isAuthenticated && isAuth) return null;

                const isCurrent = location.pathname === to;
                const className = isCurrent
                  ? "block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                  : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700";
                return (
                  <Disclosure.Button
                    key={label}
                    as={Link}
                    to={to}
                    className={className}
                  >
                    {label}
                  </Disclosure.Button>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
