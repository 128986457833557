const mixpanelEvent = {
    VISITED_STORE: 'VisitedStore',
    DEAL_CLICKED: 'DealClicked',
    VIEWED_POPUP: 'ViewedPopUp',
    MINIMIZED_POPUP: 'MinimizedPopUp',
    ACTIVE_CASHBACK: 'ActivateCashBack',
    LOGIN_ACTIVATE_CASHBACK: 'LoginToActivateCashBack',
    VIEWED_POPUP_ON_FIRST_LOAD: 'ViewedPopUpOnFirstLoad',
    CLICKED_GET_A_DEAL: 'ClickedGetADeal',
    VISITED_PAGE: 'VisitedPage',
    STARTED_CHECKOUT: 'StartedCheckout',
    OPENED_ON_PAGE_EXTENSION: 'OpenedOnPageExtension',
    NEW_USER: 'NewUser',
    PURCHASE: 'Purchase',
  };
  
  const mixpanelProperties = {
    STORE_TYPE: 'StoreType',
    STORE_NAME: 'StoreName',
    USER_LOGGED_In: 'UserLoggedIn',
    LOCATION_URL: 'LocationURL',
    LOCATION: 'Location',
    DEAL_OFFER_SHOWED: 'Deal offer showed',
    POP_UP_STATUS: 'Pop-Up Status',
    UNIQUE_ID:"UniqueID",
    PAGE:"Page",
    SOURCE:"Source"
  
  };
  

  export { mixpanelEvent, mixpanelProperties };
  