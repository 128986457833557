const mixpanelEventDataWrapped = (eventName, wrappedProperties = {}) => {
    return {
      eventName: eventName,
      properties: {
        ...wrappedProperties,
      },
    };
  };


  export default mixpanelEventDataWrapped