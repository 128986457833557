import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StarIcon } from "@heroicons/react/20/solid";

import Footer from "../../components/Footer";
import { InputWithoutLabel } from "../../components/Input";
import { SuccessModal } from "../../components/Modal";
import Navbar from "../../components/Navbar";
import { useNotification } from "../../components/Notification";

import {
  baseUrl,
  frontendBaseUrl,
  subscribeToMarketing,
  zapierWebHook,
} from "../../api";
import { classNames, getCookie } from "../../utils/dom";

import CleoMarketingBg from "../../assets/images/cleo-marketing-bg.png";
import ExtensionScreenshot from "../../assets/images/extension-screenshot.png";
import ExtensionScreenshot2 from "../../assets/images/extension-screenshot2.png";
import DotPattern from "../../assets/images/dot-pattern.png";
import DotPattern2 from "../../assets/images/dot-pattern2.png";
import DotPattern3 from "../../assets/images/dot-pattern3.png";
import YourBalance from "../../assets/images/your-balance-screenshot.png";
import Cards from "../../assets/images/cards-screenshot.png";

import FashionNovaImage from "../../assets/images/fashionnova.png";
import AloImage from "../../assets/images/alo.png";
import SkimsImage from "../../assets/images/skims.png";
import KithImage from "../../assets/images/kith.png";
import CultGaiaImage from "../../assets/images/cultgaia.png";
import AimeImage from "../../assets/images/aime.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

const logos = [
  { src: FashionNovaImage, width: 158, height: 20 },
  { src: AloImage, width: 72, height: 48 },
  { src: SkimsImage, width: 158, height: 36 },
  { src: KithImage, width: 114, height: 48 },
  { src: CultGaiaImage, width: 162, height: 40 },
  { src: AimeImage, width: 150, height: 56 },
];

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const showNotification = useNotification();
  const { isAuthenticated } = useAuth0();
  const [email, setEmail] = React.useState("");
  const [successModalOpen, setSuccessModalOpen] = React.useState(false);
  const [marketingProcessing, setMarketingProcessing] = React.useState(false);
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated]);
  React.useEffect(() => {
    if (!successModalOpen) {
      setEmail("");
    }
  }, [successModalOpen]);

  function generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");
    const campaign = queryParams.get("campaign");
    const uuid = generateUUID();
    const loggedOutUserId = getCookie("userId");

    // Function to set a cookie
    const setCookie = (name, value, days) => {
      const expires = new Date();
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000); // Set expiration time
      document.cookie = `${name}=${encodeURIComponent(
        value
      )};expires=${expires.toUTCString()};path=/`;
    };

    // Set cookies for source and campaign if they exist
    if (source) {
      setCookie("source", source, 7); // Cookie expires in 7 days
    }
    setCookie("userId", loggedOutUserId ? loggedOutUserId : uuid, 7);
    if (campaign) {
      setCookie("campaign", campaign, 7); // Cookie expires in 7 days
    }

    // Uncomment if you want to redirect
    // window.location.href = process.env.REACT_APP_EXTENSION_URL;
  }, [location.search]); // Run useEffect when location.search changes

  const handleOpenExtension = () => {
    const extensionUrl = process.env.REACT_APP_EXTENSION_URL;
    window.open(extensionUrl, "_blank");

    window.fbq("trackCustom", "Clicked install extension button");
    window.ttq.track("ClickedInstallExtensionButton");
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();

    setMarketingProcessing(true);

    const userId = getCookie("userId");
    const source = getCookie("source");

    const url = new URL(`${frontendBaseUrl}/invited_by_link`);

    if (userId) {
      url.searchParams.append("userId", userId);
    }
    if (source) {
      url.searchParams.append("source", source);
    }

    const finalUrl = url.toString();

    try {
      await zapierWebHook(email, finalUrl);
      await subscribeToMarketing(email);
      setEmail("");
      window.fbq("track", "Lead");
      window.ttq.track("Lead");

      setSuccessModalOpen(true);
    } catch (err) {
      setEmail("");
      showNotification({
        title: "Invalid email",
        description: "Your email is already on the list.",
        type: "error",
      });
    }

    setMarketingProcessing(false);
  };

  return (
    <>
      <Navbar />
      <div className="overflow-hidden bg-white pt-24 sm:pt-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pl-4 lg:py-48">
              <div className="relative z-[1] lg:max-w-3xl">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Save more with Cleo's Chrome extension
                </p>
                <p className="mt-6 text-xl leading-7 text-gray-500">
                  Cleo helps you find and buy discounted gift cards at checkout,
                  so you can stack savings and keep more money in your pocket.
                </p>
                <div className="hidden sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-indigo-600 mt-12 px-8 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleOpenExtension}
                  >
                    Add to Chrome - It's Free
                  </button>
                  <div className="mt-9">
                    <div className="flex items-center">
                      <div className="flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <StarIcon
                            key={rating}
                            className="text-yellow-400 h-5 w-5 flex-shrink-0"
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                      <p className="text-sm pt-0 h-7 leading-7 font-medium text-gray-900 border-l border-gray-300 pl-5 ml-5">
                        Chrome Store reviews
                      </p>
                    </div>
                  </div>
                </div>
                <div className="relative overflow-hidden bg-indigo-50 rounded-3xl mt-6 px-4 py-6 sm:hidden">
                  <div className="text-gray-900 text-base font-bold leading-normal">
                    Get Cleo for Free
                  </div>
                  <div className="relative z-[1] indextext-gray-900 text-base font-normal leading-normal mt-4">
                    Cleo is only for your laptop or desktop right now. Just type
                    in your email and we'll send you a link so you can install
                    it.
                  </div>
                  <form onSubmit={handleSubmitEmail} className="mt-4">
                    <InputWithoutLabel
                      required
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleChangeEmail}
                    />
                    <button
                      type="submit"
                      disabled={marketingProcessing}
                      className={classNames(
                        "w-full rounded-md bg-indigo-600 mt-4 px-8 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                        marketingProcessing && "opacity-50"
                      )}
                    >
                      Get Started
                    </button>
                  </form>
                  <div className="mt-6">
                    <div className="flex items-center">
                      <div className="flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <StarIcon
                            key={rating}
                            className="text-yellow-400 h-5 w-5 flex-shrink-0"
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                      <p className="text-sm pt-0 h-7 leading-7 font-medium text-gray-900 border-l border-gray-300 pl-5 ml-5">
                        Chrome Store reviews
                      </p>
                    </div>
                  </div>
                  <img
                    src={CleoMarketingBg}
                    alt="Cleo Marketing"
                    className="absolute top-0 right-0"
                    width={160}
                    height={122}
                  />
                </div>
              </div>
            </div>
            <div className="relative flex items-start justify-end lg:order-first">
              <img
                src={DotPattern}
                alt="Dot pattern"
                className="absolute top-0 right-0"
                width={404}
                height={392}
              />
              <img
                src={ExtensionScreenshot}
                alt="Extension screenshotTest"
                className="relative w-[48rem] max-w-none sm:w-[57rem] -top-8 -right-[13.8rem]"
                width={932}
                height={976}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
        <p className="text-base leading-6 font-medium tracking-wider uppercase text-gray-600 text-center mb-8">
          Supported Stores
        </p>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {logos.map(({ src, width, height }, index) => (
            <li
              key={index}
              className="h-28 flex col-span-1 divide-y divide-gray-200 bg-white text-center"
            >
              <img
                className="m-auto"
                src={src}
                alt="Supported store logo"
                width={width}
                height={height}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="overflow-hidden bg-white py-4">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex items-center">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  Turn your unwanted gift cards into cash
                </p>
                <p className="mt-4 text-lg leading-7 text-gray-500">
                  CleoCards.com is the marketplace for buying and selling gift
                  cards. List your unused or unwanted gift cards and get cash in
                  return.
                </p>
                <button
                  type="button"
                  className="rounded-md mt-6 leading-6 bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => navigate("/sell-gift-cards")}
                >
                  Sell Your Gift Cards
                </button>
              </div>
            </div>
            <img
              src={YourBalance}
              alt="Your balance screenshot"
              className="max-w-100 md:-ml-4 lg:-ml-0"
              width={636}
              height={647}
            />
          </div>
        </div>
      </div>
      <div className="overflow-hidden bg-white py-4">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex items-center">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  Keep your gift cards organized and never miss an expiration
                  date
                </p>
                <p className="mt-4 text-lg leading-7 text-gray-500">
                  With Cleo's "Your Cards" feature, you can upload your gift
                  cards, track their expiration dates, and keep them organized
                  in one place. Plus, get reminders when your cards are about to
                  expire.
                </p>
                <button
                  type="button"
                  className="rounded-md mt-6 leading-6 bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => navigate("/cards")}
                >
                  Get Started with "Your Cards"
                </button>
              </div>
            </div>
            <img
              src={Cards}
              alt="Cards screenshot"
              className="max-w-100 md:-ml-4 lg:-ml-0 lg:order-first"
              width={560}
              height={647}
            />
          </div>
        </div>
      </div>
      <div className="overflow-hidden bg-white pt-72 lg:py-24">
        <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
          <img
            src={ExtensionScreenshot2}
            alt="Extension screenshot 2"
            className="absolute z-10 -top-72 left-0 md:-top-60 md:left-20 lg:top-14 lg:left-10"
            width={469}
            height={462}
          />
          <div className="relative overflow-hidden bg-indigo-600 rounded-3xl flex items-center h-[28rem] px-6 md:px-10 md:h-[32rem] lg:ml-52">
            <img
              src={DotPattern2}
              alt="Dot pattern"
              className="hidden absolute top-0 right-0 sm:block"
              width={269}
              height={384}
            />
            <img
              src={DotPattern3}
              alt="Dot pattern"
              className="hidden absolute bottom-0 left-0 sm:block"
              width={269}
              height={192}
            />
            <div className="relative lg:ml-[30%] lg:max-w-lg">
              <p className="mt-2 text-3xl leading-10 font-extrabold tracking-tight text-white sm:text-4xl">
                Cleo + Coupon Codes
              </p>
              <p className="mt-6 text-lg leading-6 text-indigo-200">
                You can supercharge your savings by combining discounted gift
                cards with coupon codes.
              </p>
              <button
                type="button"
                className="rounded-md mt-6 leading-6 bg-white px-5 py-3 text-base font-medium text-indigo-600 shadow-sm hover:bg-neutral-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-100"
                onClick={handleOpenExtension}
              >
                Add to Chrome - It's Free
              </button>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        open={successModalOpen}
        onOpen={setSuccessModalOpen}
        title="Check your email"
        description="The link is on its way to your inbox as we speak so you can start saving with Cleo!"
      />
      <Footer />
    </>
  );
};

export default Home;
